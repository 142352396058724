<template>
  <div class="lesson-review-container">
    <div class="lesson-review-content">
      <div class="chapter-area">
        <div
          class="lesson-item"
          v-for="(item, index) in reviewLesson"
          :key="index"
          @click="chooseLesson(item)"
        >
          <div class="lesson-img">
            <img
              src="@/assets/img/07-Stars/star-small.svg"
              alt=""
              v-if="item.status"
            />
            <img src="@/assets/img/19-Dashboard/lock.svg" alt="" v-else />
          </div>
          <div
            class="lesson-title font-menu-small"
            :class="{ lessonActived: currentLessonIndex === index + 1 }"
          >
            <div class="title">{{ item.title }}</div>
            <div class="title-chinese">{{ item.titleChinese }}</div>
          </div>
        </div>
      </div>
      <div class="lesson-area" v-if="hasFinishedClass">
        <div class="lesson-info-box">
          <div class="lesson-info">
            <div class="lesson-info-left">
              <div>LESSON {{ currentLessonInfo.lessonId }}</div>
              <div>{{ currentLessonInfo.classHanzi }}</div>
              <div class="pinyin">{{ currentLessonInfo.classPinyin }}</div>
            </div>
            <div class="lesson-info-right">
              <div>{{ currentLessonInfo.titleEnglish }}</div>
              <div>{{ currentLessonInfo.titleHanzi }}</div>
              <div class="pinyin">{{ currentLessonInfo.titlePinyin }}</div>
            </div>
          </div>
        </div>

        <div class="lesson-review-area">
          <!-- nav -->
          <div class="review-class-nav">
            <div
              class="nav-item"
              v-for="(item, index) in reviewNavList"
              :key="index + 'nav'"
              @click="chooseReviewNav(index)"
              :class="{
                navActived: currentNavIndex === index,
                noDisPlaySong: currentLessonIndex % 2 === 1 && index === 4,
                noDisPlayWriting: currentLessonIndex % 2 === 0 && index === 3,
                firstDistance: currentLessonIndex % 2 === 0 && index === 0,
              }"
            >
              <div class="nav-img">
                <img :src="item.img" alt="" />
              </div>
              <div class="title font-menu-small">{{ item.name }}</div>
            </div>
          </div>
          <!-- content -->
          <div
            class="review-class-content"
            :class="{ noPadding: [3, 5].indexOf(currentNavIndex) > -1 }"
          >
            <div class="syllabus-area" v-if="currentNavIndex === 0">
              <Syllabus :syllabusInfo="currentLessonInfo" />
            </div>

            <div class="review-area" v-if="currentNavIndex === 1">
              <div class="class-content">
                <div class="review-left">
                  <div class="title">CLASS CONTENT</div>
                  <div class="tips">
                    Review your class materials including texts and vocabulary
                    here.
                  </div>
                </div>
                <div
                  class="button-right font-menu-small"
                  @click="handleToPreview"
                >
                  Review Class Content
                </div>
              </div>
              <!-- <div class="class-video">
                <div class="review-left">
                  <div class="title">CLASS VIDEO RECORDING</div>
                  <div class="tips">
                    Watch a full recording of your class with the teacher here.
                  </div>
                </div>
                <div class="button-right font-menu-small">
                  <i class="el-icon-view"></i> Watch Class Recording
                </div>
              </div> -->
            </div>

            <div class="homework-area" v-if="currentNavIndex === 2">
              <div class="record-content">
                <div
                  class="record-item"
                  v-for="(item, index) in homewordRecordList"
                  :key="index + 'homework'"
                >
                  <InteractiveRecord
                    :recordInfo="item"
                    v-if="homewordRecordList && homewordRecordList.length > 0"
                  />
                </div>
              </div>
            </div>

            <div class="song-area" v-if="currentNavIndex === 3">
              <div class="song-box">
                <SingPage :lessonNo="currentLessonInfo.lessonId"/>
              </div>
            </div>

            <div class="writing-area" v-if="currentNavIndex === 4">
              <div class="record-content">
                <div
                  class="record-item"
                  v-for="(item, index) in writingRecordList"
                  :key="index + 'writing'"
                >
                  <InteractiveRecord
                    :recordInfo="item"
                    :recordType="1"
                    v-if="writingRecordList && writingRecordList.length > 0"
                  />
                </div>
              </div>
            </div>

            <div
              class="evaluation-area"
              v-if="currentNavIndex === 5"
              :class="{ centerArea: isLockedClass }"
            >
              <div class="evaluation-content" v-if="!isLockedClass">
                <PageLayout>
                  <template #page-name>
                    <div class="nav-title font-menu-large">
                      <div
                        class="nav-title-item"
                        v-for="item in feedbackClassifyList"
                        :key="item.id"
                      >
                        {{ item.title }}
                      </div>
                    </div>
                  </template>
                  <template #page-content>
                    <div class="content">
                      <!-- lesson teacher feedback -->
                      <div
                        class="feedback-content teacher"
                        v-show="feedbackClassify === 0 && !isChapterComment"
                      >
                        <div
                          class="area-feedback"
                          v-if="isTeacherFinishFeedback"
                        >
                          <div class="teacher-info">
                            <div class="teacher-avatar">
                              <img
                                :src="teacherFeedbackOfLessonInfo.teacherAvatar"
                                alt=""
                              />
                            </div>
                            <div class="teacher-name font-menu-medium">
                              {{ teacherFeedbackOfLessonInfo.teacherName }} |
                              {{
                                teacherFeedbackOfLessonInfo.teacherNamePinyin
                              }}
                            </div>
                          </div>
                          <div
                            class="comment"
                            v-for="(item, index) in teacherFeedbackOfSkillList"
                            :key="index + 'comment'"
                          >
                            <div class="comment-title">{{ item.title }}</div>
                            <div class="star-level">
                              <div
                                class="img-item"
                                v-for="(ele, index) in 5"
                                :key="index + 'teacher-star'"
                              >
                                <img
                                  src="@/assets/img/07-Stars/star-empty.svg"
                                  alt=""
                                  v-if="item.level <= index"
                                />
                                <img
                                  src="@/assets/img/07-Stars/star-half.svg"
                                  alt=""
                                  v-if="item.half === 1 && index === item.index"
                                />
                                <img
                                  src="@/assets/img/07-Stars/star-full.svg"
                                  alt=""
                                  v-if="item.index > index"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="comment-content">
                            <div class="title">Comment</div>
                            <p class="content">
                              {{ teacherFeedbackOfLessonInfo.commentContent }}
                            </p>
                          </div>
                        </div>
                        <div class="no-feedBack font-menu-small" v-else>
                          {{ teacherFeedbackOfLessonInfo.message }}
                        </div>
                      </div>
                      <!-- chapter teacher feedback -->

                      <div
                        class="feedback-content average"
                        v-show="feedbackClassify === 0 && isChapterComment"
                      >
                        <div class="average-star-level">
                          <div class="teacher-info">
                            <div class="teacher-avatar">
                              <img
                                :src="teacherFeedbackOfChapter.teacherAvatar"
                                alt=""
                              />
                            </div>
                            <div class="teacher-name font-menu-medium">
                              {{ teacherFeedbackOfChapter.teacherName }} |
                              {{ teacherFeedbackOfChapter.teacherNamePinyin }}
                            </div>
                          </div>
                          <div class="star-level">
                            <StarScore
                              :score="
                                String(teacherFeedbackOfChapter.averageScore)
                              "
                            />
                          </div>
                        </div>
                        <div class="average-classify">
                          <div class="skill-box">
                            <div
                              class="average-classify-item"
                              v-for="(
                                item, index
                              ) in teacherFeedbackOfChapter.classifyComments"
                              :key="index + 'classify'"
                            >
                              <div class="title">{{ item.title }}</div>
                              <div class="star-score">
                                <StarScore :score="String(item.score)" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="comment-content"
                          v-for="(
                            item, index
                          ) in teacherFeedbackCommentOfChapter"
                          :key="index + 'average'"
                        >
                          <div class="title">{{ item.title }}</div>
                          <p class="content">
                            {{ item.content }}
                          </p>
                        </div>
                      </div>

                      <div
                        class="finished-stu-feedback"
                        v-if="feedbackClassify === 1 && isStuFinishedFeedback"
                      ></div>
                    </div>
                  </template>
                </PageLayout>
              </div>
              <div class="lock-class font-menu-large" v-else>
                You haven't finished the lesson yet !
              </div>
            </div>
          </div>

          <div
            v-if="currentNavIndex == 2 || currentNavIndex == 4"
            class="upload-file"
          >
            <div class="title">Upload a new file</div>
            <el-upload
              class="button-upload"
              :on-change="handleUpload"
              action="none"
              :data="uploadData"
              :headers="headers"
              :auto-upload="false"
            >
              <el-button size="small" type="primary">UPLOAD</el-button>
              <!-- <div slot="tip" class="el-upload__tip">
                      只能上传jpg/png文件，且不超过500kb
                    </div> -->
            </el-upload>
          </div>
        </div>
      </div>
      <div class="mask" v-else>
        <img src="@/assets/img/19-Dashboard/placeholder-lesson.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import SingPage from "./Song";
import Syllabus from "./Syllabus";
import PageLayout from "@/components/Page/Layout/PageLayout.vue";
import StarScore from "@/components/Page/StarScore";
import InteractiveRecord from "@/components/Page/InteractiveRecord";

import {
  getTeacherFeedbackOfChapter,
  getTeacherFeedbackOfLesson,
  submitStudentComment,
  getUserLessonHomework,
  uploadHomeworkFile,
  addHomework,
} from "@/assets/js/axios/kidsApi";

import { levelOneList } from "@/assets/js/default/levelOne";
export default {
  name: "LessonReview",
  props: {
    reviewLesson: {
      type: Array,
      require: true,
    },
    chooseChapterNo: {
      type: Number,
      require: true,
    },
  },
  components: {
    SingPage,
    PageLayout,
    StarScore,
    InteractiveRecord,
    Syllabus,
  },
  data() {
    return {
      headers: {},
      isStuFinishedFeedback: false,
      isLockedClass: false,
      homewordRecordList: [
        // {
        //   type: 0, // 0 老师添加文件   1 学生提交作业  2 老师查看作业  后续待补充
        //   date: "2022-01-31",
        //   avatar: require("@/assets/img/19-Dashboard/teacher.svg"),
        //   fileName: "NihaoKids_Homework_Lesson_01.pdf", //paperclip
        // },
        // {
        //   type: 1,
        //   date: "2022-01-31",
        //   avatar: require("@/assets/img/19-Dashboard/profile.svg"),
        //   fileName: "NihaoKids_Homework_Lesson_01_Raul.pdf", //paperclip
        // },
        // {
        //   type: 2,
        //   date: "2022-01-31",
        //   avatar: require("@/assets/img/19-Dashboard/teacher.svg"),
        // },
      ],
      recordInfoList: [],

      writingRecordList: [
        // {
        //   type: 0, // 0 老师添加文件   1 学生提交作业  2 老师查看作业  后续待补充
        //   date: "2022-01-31",
        //   avatar: require("@/assets/img/19-Dashboard/teacher.svg"),
        //   fileName: "NihaoKids_Writing_Sheet_Lesson_01.pdf", //paperclip
        // },
        // {
        //   type: 1,
        //   date: "2022-01-31",
        //   avatar: require("@/assets/img/19-Dashboard/profile.svg"),
        //   fileName: "NihaoKids_Writing_Sheet_Lesson_01_Raul.pdf.pdf", //paperclip
        // },
        // {
        //   type: 2,
        //   date: "2022-01-31",
        //   avatar: require("@/assets/img/19-Dashboard/teacher.svg"),
        // },
      ],

      starLevel: 0, //星星等级 0 空 1 半 2 满
      studentInput: "",
      difficultyOfLesson: null,
      starFlag: 0,
      starArr: [
        { starLevel: 0 },
        { starLevel: 0 },
        { starLevel: 0 },
        { starLevel: 0 },
        { starLevel: 0 },
      ],
      teacherFeedbackOfLessonInfo: [],
      isChapterComment: false,
      teacherFeedbackCommentOfChapter: [],
      teacherFeedbackOfChapter: {},
      isTeacherFinishFeedback: true,
      feedbackClassify: 0,
      feedbackClassifyList: [
        {
          id: 0,
          title: "Teacher's Feedback",
        },
        // {
        //   id: 1,
        //   title: "Your Feedback",
        // },
      ],
      feedbackDialogVisible: true,

      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì Dōngdōng.",
            chineseWords: "你好！你好！我是冬冬。",
          },
          {
            id: 2,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì Xiǎo'ài.",
            chineseWords: "你好！你好！我是小爱。",
          },
          {
            id: 3,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì BB",
            chineseWords: "你好！你好！我是BB。",
          },
          {
            id: 4,
            spelling: "Nǐ hǎo! Nǐ hǎo! Wǒ shì ...",
            chineseWords: "你好！你好！我是...",
          },
        ],
      },

      reviewNavList: [
        {
          id: 0,
          img: require("@/assets/img/19-Dashboard/icon-goals.svg"),
          name: "Syllabus",
        },
        {
          id: 1,
          img: require("@/assets/img/19-Dashboard/icon-review.svg"),
          name: "Review",
        },
        {
          id: 2,
          img: require("@/assets/img/19-Dashboard/icon-homework.svg"),
          name: "Homework",
        },
        {
          id: 3,
          img: require("@/assets/img/19-Dashboard/icon-song.svg"),
          name: "Song",
        },
        {
          id: 4,
          img: require("@/assets/img/19-Dashboard/icon-writing.svg"),
          name: "Writing Sheet",
        },

        {
          id: 5,
          img: require("@/assets/img/19-Dashboard/icon-evaluation.svg"),
          name: "Evaluation",
        },
      ],
      currentLessonIndex: 1,
      currentNavIndex: null,
      currentLessonInfo: {},

      syllabusList: [],
      hasFinishedClass: true,
      lesssonIdIndex: null,
    };
  },
  created() {
    const { token, refreshToken } = JSON.parse(
      localStorage.getItem("userData")
    );
    this.headers.token = token;
    this.headers.refreshToken = refreshToken;
    this.headers["Content-Type"] = "multipart/form-data";

    this.initLessonReview();
    this.calendarEventId = this.currentLessonInfo.calendarEventId;
  },
  watch: {
    currentLessonIndex(value) {
      if ([7, 8].indexOf(value) === -1) {
        this.currentLessonInfo.syllabus[1].title =
          value % 2 === 1 ? "Phrases" : "Strokes";
      }
    },
  },
  computed: {
    uploadData() {
      console.log(this.currentLessonInfo);
      return {
        // calendarEventId: this.currentLessonInfo.calendarEventId,
        // // calendarEventId: this.currentLessonInfo.calendarEventId,
        // isTeacher: true,
        // homeworkId: this.homewordRecordList[0].homeworkId,
      };
    },
    /**
     * @description: 老师feedback 数据
     * @param {*}
     * @return {*}
     */
    teacherFeedbackOfSkillList() {
      let arr;
      if (
        this.teacherFeedbackOfLessonInfo &&
        this.teacherFeedbackOfLessonInfo.skillList
      ) {
        arr = this.teacherFeedbackOfLessonInfo.skillList.map((item, index) => {
          const interger = parseInt(item.level);
          const half = parseInt(item.level.split(".")[1]) === 5 ? 1 : 0;
          // console.log(interger, half);
          return {
            ...item,
            index: interger,
            half,
          };
        });
        return arr;
      } else {
        return [];
      }
    },
  },
  methods: {
    /**
     * @description: 初始化单个lesson Review 的数据
     * @param {*}
     * @return {*}
     */
    initLessonReview() {
      this.reviewLesson.forEach((item, index) => {
        if (item.isLocked) {
          this.currentLessonIndex = index;
          this.lesssonIdIndex = index - 1 < 0 ? 0 : index - 1;
        }
      });
      const lessonInfo = this.reviewLesson[this.lesssonIdIndex];
      // console.log(lessonInfo);
      this.currentLessonInfo = {
        ...levelOneList[lessonInfo.lessonIndex - 1],
        calendarEventId: lessonInfo.calendarEventId,
        lessonId: lessonInfo.id,
        titleHanzi: lessonInfo.titleZh,
        titlePinyin: lessonInfo.titlePy,
        titleEnglish: lessonInfo.titleEn,
        classPinyin: lessonInfo.classPy,
        classHanzi: lessonInfo.classZh,
        studentId: lessonInfo.studentId,
        teacherName: lessonInfo.teacherName + " | " + lessonInfo.teacherNamePy,
        teacherAvatar: lessonInfo.teacherAvatar,
        chpaterId: lessonInfo.lessonId,
      };
    },
    /**
     * @description: 点击选择课程
     * @param {*} item
     * @return {*}
     */
    chooseLesson(item) {
      this.currentLessonIndex =
        item.lessonIndex % 8 === 0 ? 8 : item.lessonIndex % 8;
      // console.log(this.currentLessonIndex);
      this.currentNavIndex = null;

      // 没上到课要加上遮罩
      // 获取当前课程的信息
      if (item.status || item.isLocked) {
        // console.log(item);
        this.hasFinishedClass = true;
        this.currentLessonInfo = {
          ...levelOneList[item.id - 1],
          calendarEventId: item.calendarEventId,
          lessonId: item.id,
          titleHanzi: item.titleZh,
          titlePinyin: item.titlePy,
          titleEnglish: item.titleEn,
          classPinyin: item.classPy,
          classHanzi: item.classZh,
          isLocked: item.isLocked,
          studentId: item.studentId,
          teacherId: item.teacherId,
          teacherName: item.teacherName + " | " + item.teacherNamePy,
          teacherAvatar: item.teacherAvatar,
          chpaterId: item.lessonId,
        };
      } else {
        this.hasFinishedClass = false;
      }
    },
    /**
     * @description: 选择review的模块
     * @param {*} index
     * @return {*}
     */
    chooseReviewNav(index) {
      if (!this.currentLessonIndex) {
        return;
      }
      // console.log(index);
      this.currentNavIndex = index;
      if (index === 2 || index === 4) {
        // 处理家庭作业
        if (!this.currentLessonInfo.isLocked) {
          this.getUserHomework(index);
        }
      } else if (index === 5) {
        this.isLockedClass = false;
        // console.log(this.currentLessonInfo);
        // 获取反馈
        if (this.currentLessonInfo.lessonId % 8 === 0) {
          this.isChapterComment = true;
          this.getTeacherFeedbackOfChapter();
        } else {
          this.isChapterComment = false;
          this.getTeacherFeedbackOfLesson();
        }
      }
    },
    /**
     * @description:  跳转Preview页面
     * @param {*}
     * @return {*}
     */
    handleToPreview() {
      // console.log(this.currentLessonInfo);
      localStorage.setItem("teacherName", this.currentLessonInfo.teacherName);

      this.$router.push({
        name: `PreviewLesson${this.currentLessonInfo.lessonId}`,
      });
    },
    /**
     * @description:获取学生家庭作业信息
     * @param {*}
     * @return {*}
     */
    async getUserHomework(index) {
      // 获取学生作业信息
      const res = await getUserLessonHomework({
        calendarEventId: this.currentLessonInfo.calendarEventId,
      });
      if (res.code === 200 && res.data) {
        console.log(res.data);
        // // this.homewordRecordList = [];
        this.recordInfoList = res.data
          .filter((item) => item.homeworkType === (index === 2 ? 1 : 2))
          .map((item) => {
            return {
              ...item,
              // name: item.username,
              name: item.isTeacher ? "Your teacher" : "You",
              date: item.createtime.split("T")[0],
              avatar: item.photo,
              fileName: item.fileName ? item.fileName : "测试.pdf",
              fileSrc: item.uploadFile,
              homeworkId: item.id,
              calendarEventId: item.calendarEventId,
            };
          });
        if (index === 2) {
          this.homewordRecordList = this.recordInfoList;
          // console.log(this.homewordRecordList);
        } else {
          this.writingRecordList = this.recordInfoList;
        }
      } else {
        this.$message({
          type: "error",
          message: "Network Error !",
        });
      }
    },
    /**
     * @description: 切换feedback类型
     * @param {*} index
     * @return {*}
     */
    changeFeedback(index) {
      this.feedbackClassify = index;
    },
    /**
     * @description: 点击星星评分
     * @param {*} currentIndex
     * @return {*}
     */
    clickStar(currentIndex) {
      if (this.originIndex !== currentIndex) {
        this.starFlag = 0;
      }
      if (!this.starFlag) {
        this.originIndex = currentIndex;
        this.starArr.forEach((item, index) => {
          this.currentIndex = currentIndex;
          if (index > currentIndex) {
            item.starLevel = 0;
          } else if (index === currentIndex) {
            item.starLevel = 1;
          } else {
            item.starLevel = 2;
          }
        });
        this.starFlag++;
      } else {
        this.starArr[currentIndex].starLevel = 2;
        this.currentIndex = currentIndex + 1;
        this.starFlag = 0;
      }
      const half = this.starArr[currentIndex].starLevel === 1 ? ".5" : ".0";
      this.finalLevel = this.currentIndex + half;
      // console.log(this.finalLevel);
    },
    /**
     * @description: 获取老师的chapter feedback
     * @param {*}
     * @return {*}
     */
    async getTeacherFeedbackOfChapter() {
      // console.log(this.currentLessonInfo);
      const sendData = {
        calendarEventId: this.currentLessonInfo.calendarEventId,
        studentId: this.currentLessonInfo.studentId,
        lessonId: this.currentLessonInfo.chpaterId,
        levelId: 1,
      };
      const result = await getTeacherFeedbackOfChapter(sendData);
      if (result.code === 200) {
        const res = result.data.length !== 0 ? result.data : {};

        this.teacherFeedbackOfChapter = {
          // level: 3.5,
          // index: 3,
          // half: 1,
          averageScore: parseInt(
            (res.listen +
              res.speak +
              res.readingAbility +
              res.writingAbility +
              res.attitude +
              res.interest) /
              6
          ),
          teacherName: res.teacherName,
          teacherNamePinyin: res.teacherNamePy,
          teacherAvatar: res.photo,
          classifyComments: [
            {
              title: "Listening",
              score: res.listen,
            },
            {
              title: "Speaking",
              score: res.speak,
            },
            {
              title: "Reading",
              score: res.readingAbility,
            },
            {
              title: "Writing",
              score: res.writingAbility,
            },
            {
              title: "Attitude",
              score: res.attitude,
            },
            {
              title: "Interest",
              score: res.interest,
            },
          ],
        };
        this.teacherFeedbackCommentOfChapter = [
          {
            title: "Performance in class",
            content: res.performanceInClass,
          },
          {
            title: "Room for improvement",
            content: res.roomForImprovement,
          },
          {
            title: "Learning suggestions",
            content: res.learningSuggestions,
          },
        ];
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },

    /**
     * @description: 获取老师的lesson feedback
     * @param {*}
     * @return {*}
     */
    async getTeacherFeedbackOfLesson() {
      // console.log(this.currentLessonInfo);
      if (this.currentLessonInfo.isLocked) {
        this.isLockedClass = true;
        return;
      }
      const sendData = {
        calendarEventId: this.currentLessonInfo.calendarEventId,
        studentId: this.currentLessonInfo.studentId,
      };
      const result = await getTeacherFeedbackOfLesson(sendData);
      if (result && result.code === 200) {
        const res = result.data;
        if (res.id) {
          this.isTeacherFinishFeedback = true;
          this.teacherFeedbackOfLessonInfo = {
            teacherAvatar: res.photo,
            teacherName: res.teacherName,
            teacherNamePinyin: res.teacherNamePy,
            commentContent: res.evaluationContent,
            //photo teacherName  teacherNamePy  evaluationContent
            skillList: [
              {
                title: "Knowledge Acquisition",
                level: String(res.acquisitionOfKnowledge),
              },
              {
                title: "Learning Attitude",
                level: String(res.learningAttitude),
              },
              {
                title: "Progress",
                level: String(res.situationOfProgress),
              },
            ],
          };
        } else {
          this.isTeacherFinishFeedback = false;
          this.teacherFeedbackOfLessonInfo = {
            message: "The teacher hasn't submitted any feedback yet.",
          };
        }
      }
    },
    async handleUpload(file) {
      // console.log("上传homework", file);
      let formData = new FormData();
      const fileRaw = file.raw;
      formData.append("uploadFile", fileRaw);
      const res = await uploadHomeworkFile(formData);
      if (res && res.code === 200 && res.data) {
        const sendData = {
          uploadFile: res.data,
          isTeacher: false,
          homeworkId: this.recordInfoList[0].homeworkId,
          calendarEventId: this.recordInfoList[0].calendarEventId,
          fileName: res.data.split("/").pop(),
          homeworkType: this.currentNavIndex === 2 ? 1 : 2,
        };
        const result = await addHomework(sendData);
        if (result && result.code === 200) {
          console.log("success");
          this.getUserHomework(this.currentNavIndex);
          this.recordInfoList = [];
        }
      } else {
        this.$message({
          type: "error",
          message: "Network Error!",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
