var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "review-container" },
    [
      _c("PageLayout", {
        scopedSlots: _vm._u([
          {
            key: "page-name",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "nav-title font-menu-large" },
                  _vm._l(_vm.navList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "nav-title-item",
                        class: { active: item.id === _vm.currentPage },
                      },
                      [_vm._v(" " + _vm._s(item.title) + " ")]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "review-class-title font-menu-medium" },
                  [
                    _c("div", { staticClass: "level" }, [
                      _c("div", { staticClass: "level-select" }, [
                        _vm._v(" " + _vm._s(_vm.defaultLevel) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "chapter" }, [
                      _c(
                        "div",
                        { staticClass: "chapter-select" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "Select your chapter",
                                "popper-class": "review-select-box",
                              },
                              on: { change: _vm.changeChapterOption },
                              model: {
                                value: _vm.chooseChapterNo,
                                callback: function ($$v) {
                                  _vm.chooseChapterNo = $$v
                                },
                                expression: "chooseChapterNo",
                              },
                            },
                            _vm._l(_vm.chapterList, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.label,
                                  value: item.value,
                                  "popper-class": "review-select-box",
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "page-content",
            fn: function () {
              return [
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "review-class-lesson" }, [
                    _c(
                      "div",
                      { staticClass: "lesson-content" },
                      [
                        _vm.lessonList.length
                          ? _c("LessonReview", {
                              attrs: {
                                reviewLesson: _vm.lessonList,
                                chooseChapterNo: _vm.chooseChapterNo,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }