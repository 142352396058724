var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lesson-review-container" }, [
    _c("div", { staticClass: "lesson-review-content" }, [
      _c(
        "div",
        { staticClass: "chapter-area" },
        _vm._l(_vm.reviewLesson, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "lesson-item",
              on: {
                click: function ($event) {
                  return _vm.chooseLesson(item)
                },
              },
            },
            [
              _c("div", { staticClass: "lesson-img" }, [
                item.status
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/img/07-Stars/star-small.svg"),
                        alt: "",
                      },
                    })
                  : _c("img", {
                      attrs: {
                        src: require("@/assets/img/19-Dashboard/lock.svg"),
                        alt: "",
                      },
                    }),
              ]),
              _c(
                "div",
                {
                  staticClass: "lesson-title font-menu-small",
                  class: {
                    lessonActived: _vm.currentLessonIndex === index + 1,
                  },
                },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  _c("div", { staticClass: "title-chinese" }, [
                    _vm._v(_vm._s(item.titleChinese)),
                  ]),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _vm.hasFinishedClass
        ? _c("div", { staticClass: "lesson-area" }, [
            _c("div", { staticClass: "lesson-info-box" }, [
              _c("div", { staticClass: "lesson-info" }, [
                _c("div", { staticClass: "lesson-info-left" }, [
                  _c("div", [
                    _vm._v("LESSON " + _vm._s(_vm.currentLessonInfo.lessonId)),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.currentLessonInfo.classHanzi))]),
                  _c("div", { staticClass: "pinyin" }, [
                    _vm._v(_vm._s(_vm.currentLessonInfo.classPinyin)),
                  ]),
                ]),
                _c("div", { staticClass: "lesson-info-right" }, [
                  _c("div", [
                    _vm._v(_vm._s(_vm.currentLessonInfo.titleEnglish)),
                  ]),
                  _c("div", [_vm._v(_vm._s(_vm.currentLessonInfo.titleHanzi))]),
                  _c("div", { staticClass: "pinyin" }, [
                    _vm._v(_vm._s(_vm.currentLessonInfo.titlePinyin)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "lesson-review-area" }, [
              _c(
                "div",
                { staticClass: "review-class-nav" },
                _vm._l(_vm.reviewNavList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index + "nav",
                      staticClass: "nav-item",
                      class: {
                        navActived: _vm.currentNavIndex === index,
                        noDisPlaySong:
                          _vm.currentLessonIndex % 2 === 1 && index === 4,
                        noDisPlayWriting:
                          _vm.currentLessonIndex % 2 === 0 && index === 3,
                        firstDistance:
                          _vm.currentLessonIndex % 2 === 0 && index === 0,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.chooseReviewNav(index)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "nav-img" }, [
                        _c("img", { attrs: { src: item.img, alt: "" } }),
                      ]),
                      _c("div", { staticClass: "title font-menu-small" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  staticClass: "review-class-content",
                  class: {
                    noPadding: [3, 5].indexOf(_vm.currentNavIndex) > -1,
                  },
                },
                [
                  _vm.currentNavIndex === 0
                    ? _c(
                        "div",
                        { staticClass: "syllabus-area" },
                        [
                          _c("Syllabus", {
                            attrs: { syllabusInfo: _vm.currentLessonInfo },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.currentNavIndex === 1
                    ? _c("div", { staticClass: "review-area" }, [
                        _c("div", { staticClass: "class-content" }, [
                          _vm._m(0),
                          _c(
                            "div",
                            {
                              staticClass: "button-right font-menu-small",
                              on: { click: _vm.handleToPreview },
                            },
                            [_vm._v(" Review Class Content ")]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.currentNavIndex === 2
                    ? _c("div", { staticClass: "homework-area" }, [
                        _c(
                          "div",
                          { staticClass: "record-content" },
                          _vm._l(
                            _vm.homewordRecordList,
                            function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index + "homework",
                                  staticClass: "record-item",
                                },
                                [
                                  _vm.homewordRecordList &&
                                  _vm.homewordRecordList.length > 0
                                    ? _c("InteractiveRecord", {
                                        attrs: { recordInfo: item },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm.currentNavIndex === 3
                    ? _c("div", { staticClass: "song-area" }, [
                        _c(
                          "div",
                          { staticClass: "song-box" },
                          [
                            _c("SingPage", {
                              attrs: {
                                lessonNo: _vm.currentLessonInfo.lessonId,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.currentNavIndex === 4
                    ? _c("div", { staticClass: "writing-area" }, [
                        _c(
                          "div",
                          { staticClass: "record-content" },
                          _vm._l(_vm.writingRecordList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index + "writing",
                                staticClass: "record-item",
                              },
                              [
                                _vm.writingRecordList &&
                                _vm.writingRecordList.length > 0
                                  ? _c("InteractiveRecord", {
                                      attrs: {
                                        recordInfo: item,
                                        recordType: 1,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm.currentNavIndex === 5
                    ? _c(
                        "div",
                        {
                          staticClass: "evaluation-area",
                          class: { centerArea: _vm.isLockedClass },
                        },
                        [
                          !_vm.isLockedClass
                            ? _c(
                                "div",
                                { staticClass: "evaluation-content" },
                                [
                                  _c("PageLayout", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "page-name",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "nav-title font-menu-large",
                                                },
                                                _vm._l(
                                                  _vm.feedbackClassifyList,
                                                  function (item) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: item.id,
                                                        staticClass:
                                                          "nav-title-item",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.title) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "page-content",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "content" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.feedbackClassify ===
                                                              0 &&
                                                            !_vm.isChapterComment,
                                                          expression:
                                                            "feedbackClassify === 0 && !isChapterComment",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "feedback-content teacher",
                                                    },
                                                    [
                                                      _vm.isTeacherFinishFeedback
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "area-feedback",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "teacher-info",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "teacher-avatar",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: _vm
                                                                                .teacherFeedbackOfLessonInfo
                                                                                .teacherAvatar,
                                                                              alt: "",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "teacher-name font-menu-medium",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .teacherFeedbackOfLessonInfo
                                                                              .teacherName
                                                                          ) +
                                                                          " | " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .teacherFeedbackOfLessonInfo
                                                                              .teacherNamePinyin
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._l(
                                                                _vm.teacherFeedbackOfSkillList,
                                                                function (
                                                                  item,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        index +
                                                                        "comment",
                                                                      staticClass:
                                                                        "comment",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "comment-title",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.title
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "star-level",
                                                                        },
                                                                        _vm._l(
                                                                          5,
                                                                          function (
                                                                            ele,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "div",
                                                                              {
                                                                                key:
                                                                                  index +
                                                                                  "teacher-star",
                                                                                staticClass:
                                                                                  "img-item",
                                                                              },
                                                                              [
                                                                                item.level <=
                                                                                index
                                                                                  ? _c(
                                                                                      "img",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            src: require("@/assets/img/07-Stars/star-empty.svg"),
                                                                                            alt: "",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                                item.half ===
                                                                                  1 &&
                                                                                index ===
                                                                                  item.index
                                                                                  ? _c(
                                                                                      "img",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            src: require("@/assets/img/07-Stars/star-half.svg"),
                                                                                            alt: "",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                                item.index >
                                                                                index
                                                                                  ? _c(
                                                                                      "img",
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            src: require("@/assets/img/07-Stars/star-full.svg"),
                                                                                            alt: "",
                                                                                          },
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "comment-content",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "title",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Comment"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticClass:
                                                                        "content",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm
                                                                              .teacherFeedbackOfLessonInfo
                                                                              .commentContent
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "no-feedBack font-menu-small",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .teacherFeedbackOfLessonInfo
                                                                      .message
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.feedbackClassify ===
                                                              0 &&
                                                            _vm.isChapterComment,
                                                          expression:
                                                            "feedbackClassify === 0 && isChapterComment",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "feedback-content average",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "average-star-level",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "teacher-info",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "teacher-avatar",
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    attrs: {
                                                                      src: _vm
                                                                        .teacherFeedbackOfChapter
                                                                        .teacherAvatar,
                                                                      alt: "",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "teacher-name font-menu-medium",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .teacherFeedbackOfChapter
                                                                          .teacherName
                                                                      ) +
                                                                      " | " +
                                                                      _vm._s(
                                                                        _vm
                                                                          .teacherFeedbackOfChapter
                                                                          .teacherNamePinyin
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "star-level",
                                                            },
                                                            [
                                                              _c("StarScore", {
                                                                attrs: {
                                                                  score: String(
                                                                    _vm
                                                                      .teacherFeedbackOfChapter
                                                                      .averageScore
                                                                  ),
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "average-classify",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "skill-box",
                                                            },
                                                            _vm._l(
                                                              _vm
                                                                .teacherFeedbackOfChapter
                                                                .classifyComments,
                                                              function (
                                                                item,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key:
                                                                      index +
                                                                      "classify",
                                                                    staticClass:
                                                                      "average-classify-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "title",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.title
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "star-score",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "StarScore",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                score:
                                                                                  String(
                                                                                    item.score
                                                                                  ),
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._l(
                                                        _vm.teacherFeedbackCommentOfChapter,
                                                        function (item, index) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              key:
                                                                index +
                                                                "average",
                                                              staticClass:
                                                                "comment-content",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "title",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.title
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "content",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.content
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                  _vm.feedbackClassify === 1 &&
                                                  _vm.isStuFinishedFeedback
                                                    ? _c("div", {
                                                        staticClass:
                                                          "finished-stu-feedback",
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1844907619
                                    ),
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "lock-class font-menu-large" },
                                [
                                  _vm._v(
                                    " You haven't finished the lesson yet ! "
                                  ),
                                ]
                              ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm.currentNavIndex == 2 || _vm.currentNavIndex == 4
                ? _c(
                    "div",
                    { staticClass: "upload-file" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Upload a new file"),
                      ]),
                      _c(
                        "el-upload",
                        {
                          staticClass: "button-upload",
                          attrs: {
                            "on-change": _vm.handleUpload,
                            action: "none",
                            data: _vm.uploadData,
                            headers: _vm.headers,
                            "auto-upload": false,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("UPLOAD")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _c("div", { staticClass: "mask" }, [
            _c("img", {
              attrs: {
                src: require("@/assets/img/19-Dashboard/placeholder-lesson.svg"),
                alt: "",
              },
            }),
          ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "review-left" }, [
      _c("div", { staticClass: "title" }, [_vm._v("CLASS CONTENT")]),
      _c("div", { staticClass: "tips" }, [
        _vm._v(
          " Review your class materials including texts and vocabulary here. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }