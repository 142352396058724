<template>
  <div class="review-container">
    <PageLayout>
      <template #page-name>
        <div class="nav-title font-menu-large">
          <div
            class="nav-title-item"
            v-for="item in navList"
            :key="item.id"
            :class="{ active: item.id === currentPage }"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="review-class-title font-menu-medium">
          <!-- level select -->
          <div class="level">
            <div class="level-select">
              {{ defaultLevel }}
              <!-- <el-select
                v-model="chooseLevel"
                :placeholder="chooseLevel ? '' : 'Select your Level'"
                popper-class="review-select-box"
                @change="changeLevelOption" 
              >
                <el-option
                  v-for="item in levelsList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  popper-class="review-select-box-option"
                >
                </el-option>
              </el-select> -->
            </div>
          </div>
          <!-- chapter select -->
          <div class="chapter">
            <div class="chapter-select">
              <el-select
                v-model="chooseChapterNo"
                placeholder="Select your chapter"
                popper-class="review-select-box"
                @change="changeChapterOption"
              >
                <el-option
                  v-for="item in chapterList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  popper-class="review-select-box"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </template>
      <template #page-content>
        <div class="content">
          <div class="review-class-lesson">
            <div class="lesson-content">
              <LessonReview
                :reviewLesson="lessonList"
                :chooseChapterNo="chooseChapterNo"
                v-if="lessonList.length"
              />
            </div>
          </div>
        </div>
      </template>
    </PageLayout>
  </div>
</template>

<script>
import { getUserLessonInfo, changeCurrentKid } from "@/assets/js/axios/kidsApi";

import PageLayout from "@/components/Page/Layout/PageLayout.vue";
import LessonReview from "./LessonReview";
export default {
  name: "Review",
  components: {
    PageLayout,
    LessonReview,
  },
  data() {
    return {
      defaultLevel: "Level 1",
      defaultChapter: 1,
      currentPage: 1,
      navList: [
        {
          title: "Review your classes",
          id: 1,
        },
      ],
      allLessonList: [],
      lessonList: [],
      chapterList: [],
      levelsList: [],
      chooseChapterNo: 1,
      chooseLevel: 1,
      isCurrentChapter: false,
      currentLessonNo: 1,
    };
  },
  computed: {
    kidUserId() {
      return this.$store.state.dashboard.kidUserId;
    },
  },
  created() {
    this.getUserLessonInfo();
  },
  watch: {
    /**
     * @description: 学生的id,切换学生后做内部登录
     * @param {*} value：选中学生的id
     * @return {*}
     */
    async kidUserId(value) {
      console.log(value);
      this.userId = value;
      if (!this.$store.state.dashboard.isKid) {
        const sendData = {
          id: this.kidUserId,
        };
        const result = await changeCurrentKid(sendData);
        if (result && result.code === 200) {
          localStorage.userData = JSON.stringify(result.data);
          this.$store.commit("setUserInfo", result.data);
          this.initReviewPage();
          this.getUserLessonInfo();
        }
      }
    },
  },
  methods: {
    /**
     * @description: 初始化review页面，做切换学生准备
     * @param {*}
     * @return {*}
     */
    initReviewPage() {
      this.allLessonList = [];
      this.chapterList = [];
      this.chooseChapterNo = 1;
      this.currentLessonNo = 1;
      this.lessonList = [];
    },
    /**
     * @description: 获取学生review课程信息
     * @param {*}
     * @return {*}
     */
    async getUserLessonInfo() {
      const result = await getUserLessonInfo();
      if (result.code === 200) {
        const res = result.data ? result.data : {};
        this.chapterList = res.levelMap[this.chooseLevel].map((item, index) => {
          return {
            value: Number(index + 1),
            label: `Chapter ${item.lessonNo} | ${item.titleZh}`,
            isLocked: null,
          };
        });
        this.initReviewData(res);
      }
    },
    /**
     * @description: 初始化学生课程信息
     * @param {*} res：接口数据
     * @return {*}
     */
    initReviewData(res) {
      /**
       * 区分 已经上完的课  即将上的一节课   剩余未完成的课
       * 默认定义到上完的最后一节课
       */
      res.chapterList.forEach((item) => {
        if (item.isLocked) {
          this.currentLessonNo = item.id - 1;
        }
      });
      this.chooseChapterNo = Math.ceil(res.chapterList.length / 8);
      for (let i = 0; i < 64; i++) {
        this.allLessonList.push(
          i < res.chapterList.length
            ? res.chapterList[i]
            : { hasFinishedClass: false }
        );
      }
      this.lessonList = this.allLessonList.slice(
        (this.chooseChapterNo - 1) * 8,
        this.chooseChapterNo * 8
      );
      this.handleReviewInfoList(this.chooseChapterNo);
    },
    /**
     * @description: 切换chapter
     * @param {*} value：chapter的nNo
     * @return {*}
     */
    changeChapterOption(value) {
      this.lessonList = null;
      this.chooseChapterNo = value;
      this.lessonList = this.allLessonList.slice((value - 1) * 8, value * 8);
      this.handleReviewInfoList(value);
    },
    /**
     * @description: 获取当前chaprer的lesson数据，封装好lesson信息
     * @param {*} chapterNo
     * @return {*}
     */
    handleReviewInfoList(chapterNo) {
      this.lessonList = this.lessonList.map((item, index) => {
        return {
          ...item,
          status: item.id ? (item.isLocked ? false : true) : false,
          title: item.id
            ? `lesson ${item.id}`
            : `lesson ${8 * (chapterNo - 1) + index + 1}`,
          titleChinese: item.id
            ? `第${item.id}课`
            : `第${8 * (chapterNo - 1) + index + 1}课`,
          lessonIndex: item.id ? item.id : 8 * (chapterNo - 1) + index + 1,
        };
      });
    },

    // changeLevelOption(value) {
    //   this.defaultLevel = value;
    //   this.initReviewData(this.reviewClassInfo);
    // },
  },
};
</script>


<style lang="scss" scoped>
@import "./index.scss";
</style>



<style lang="scss">
.chapter-select,
.level-select {
  .el-input--suffix {
    background: #224e96;
    border-radius: 20px;
    .el-input__inner {
      border: none;
      background: #224e96;
      color: #fff;
      border-radius: 35px;
      font-family: "GenSenRoundedTW" !important;
      font-weight: 600;
    }

    .el-icon-arrow-up {
      color: #ffffff;
      font-size: 20px;
    }
  }
}

.review-select-box {
  border-radius: 20px;
  margin-top: 35px;

  .el-scrollbar {
    .el-scrollbar__wrap {
      overflow: auto;
      margin-right: 0px !important;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    border-radius: 20px;
    .el-select-dropdown__list {
      padding: 10px 5px !important;
    }
    .el-scrollbar__bar {
      display: none;
    }

    .el-select-dropdown__wrap {
      background-color: #224e96;
      border-radius: 20px;
      margin-bottom: 0 !important;
      .el-select-dropdown__item {
        color: #ffffff;
        // font-size: 1.2rem;
        height: 30px;
        line-height: 30px;
        // text-align: center;

        &.hover {
          color: #224e96;
          background-color: #fff;
          border-radius: 10px;
        }
      }
    }
  }
  .popper__arrow {
    display: none;
  }
}
</style>

