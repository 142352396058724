<template>
  <div class="interactive-record-container">
    <div class="record-content">
      <div class="date">
        {{ recordInfo.date }}
      </div>
      <div class="add-file-area">
        <div class="avatar">
          <img :src="recordInfo.avatar" alt="" />
        </div>
        <div class="title">
          {{ recordInfo.name }} added a new {{ fileTypeName }}
        </div>
      </div>
      <div class="file-area">
        <div class="file-name">
          <img src="@/assets/img/19-Dashboard/paperclip.svg" alt="" />
          <span class="file-name">{{ recordInfo.fileName }}</span>
        </div>
        <!-- <span
          class="button-area view"
          @click="viewReviewFile(recordInfo.fileSrc, recordInfo.fileName)"
        >
          VIEW
        </span> -->
        <span
          class="button-area download"
          @click="viewReviewFile(recordInfo.fileSrc, recordInfo.fileName)"
        >
          DOWNLOAD
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InteractiveRecord",
  props: {
    recordInfo: {
      type: Object,
      require: true,
      default: () => {},
    },
    recordType: {
      type: Number,
      require: true,
      default: 0,
    },
  },
  computed: {
    fileTypeName() {
      if (this.recordType === 0) {
        return this.homeworkString;
      } else {
        return this.writingkString;
      }
    },
  },
  data() {
    return {
      teacherWatchString: "Your teacher has seen ",
      homeworkString: "homework file.",
      writingkString: "writing sheet.",
    };
  },
  created() {
    console.log(this.recordInfo, this.recordType);
  },
  methods: {
    /**
     * @description: 下载文件
     * @param {*} type
     * @return {*}
     */
    async downloadAndReviewFile(blob, fileName) {
      if (blob) {
        // let blobFile = new Blob([blob);
        // let file = URL.createObjectURL(blobFile);
        let downloadElement = document.createElement("a");
        // console.log(downloadElement, file);

        downloadElement.href = blob;
        downloadElement.target = "_blank";
        downloadElement.download = fileName;
        //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
      } else {
        // view
      }
    },
    /**
     * @description: 预览文件
     * @param {*} 文件地址
     * @return {*}
     */
    viewReviewFile(file, fileName) {
      console.log(file);
      if (file) {
        window.open(file, fileName);
      } else {
        // view
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.interactive-record-container {
  width: 100%;
  height: 100%;
  .record-content {
    height: 100%;

    .date {
      margin-bottom: 10px;
    }
    .add-file-area {
      display: flex;
      align-items: center;
      height: 20%;
      margin-left: 3%;
      margin-bottom: 10px;
      .avatar {
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 2%;
        img {
          width: 35px;
          border-radius: 50%;
        }
      }
      .title {
      }
    }
    .file-area {
      margin-left: 5%;
      background: #fafafa;
      padding: 2% 3%;
      border-radius: 10px;
      .file-name {
        margin-bottom: 10px;
        .file-name {
          margin-left: 5px;
        }
      }
      .button-area {
        display: inline-block;
        padding: 1.5% 4%;
        text-align: center;
        border-radius: 30px;
        cursor: pointer;
        margin-top: 10px;
      }
      .download {
        // width: 18%;
        color: #fff;
        background: #cd4c3f;
        transition: 0.3s;
        &:hover {
          background-color: #a93e33;
        }
      }
      .view {
        // width: 10%;
        margin-right: 20px;
        color: #224e96;
        background: #e0e9f7;
        transition: 0.3s;

        &:hover {
          background-color: #d8d8d8;
        }
      }
    }
  }
}
</style>
