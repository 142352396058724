export const levelOneList = [
    //chapter 1
    {
        // lessonId: 1,
        // nameHanzi: "你好！我是东东 (上)",
        // namePinyin: "Nǐ hǎo! Wǒ shì Dōngdōng (shàng)",
        // nameEnglish: "Hello! I am Dongdong",
        // classPinyin: "Dì yī kè",
        // classHanzi: "第一课",
        syllabus: [{
                title: 'Vocabulary',
                tips: "In this lesson, you’ll learn new words such as “you”, “good”, “I”, “am/is/are:”",
                wordsList: [{
                        pinyin: "nǐ",
                        hanzi: "你"
                    },
                    {
                        pinyin: "hǎo",
                        hanzi: "好"
                    },
                    {
                        pinyin: "wǒ",
                        hanzi: "我"
                    },
                    {
                        pinyin: "shì",
                        hanzi: "是"
                    },
                ]
            },
            {
                title: 'Phrases',
                tips: "You’ll also learn new phrases such as the greeting “Hello!” and introduction “I am”:",
                wordsList: [{
                        pinyin: "nǐ hǎo ",
                        hanzi: "你好!"
                    },
                    {
                        pinyin: "wǒ shì ......",
                        hanzi: "我是……"
                    },
                ]
            },
            {
                title: 'Knowledge',
                tips: "And you will know how to greet people and introduce yourself by saying:",
                wordsList: [{
                    pinyin: "Nǐ hǎo   Wǒ shì Dōng dōng .",
                    hanzi: " 你好！我是冬冬。"
                }, ]
            },
        ]
    },
    {
        // lessonId: 2,
        // nameHanzi: "你好！我是东东 (下)",
        // namePinyin: "Nǐ hǎo! Wǒ shì Dōngdōng (xià)",
        // nameEnglish: "Hello! I am Dongdong",
        // classPinyin: "Dì èr kè",
        // classHanzi: "第二课",
        syllabus: [{
                title: 'Vocabulary',
                tips: "In this lesson, you’ll review the words “you”, “good”, “I”, “am/is/are” and the introduction “I am”:",
                wordsList: [{
                        pinyin: "nǐ",
                        hanzi: "你"
                    },
                    {
                        pinyin: "hǎo",
                        hanzi: "好"
                    },
                    {
                        pinyin: "wǒ",
                        hanzi: "我"
                    },
                    {
                        pinyin: "shì",
                        hanzi: "是"
                    },
                    {
                        pinyin: "wǒ shì ......",
                        hanzi: "我是……"
                    }
                ]
            },
            {
                title: 'Strokes',
                tips: "You’ll also learn how to write the two basic strokes — horizontal and vertical:",
                wordsList: [{
                        pinyin: "héng",
                        strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-02-heng.svg")
                    },
                    {
                        pinyin: "shù",
                        strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-02-shu.svg")

                    },
                ]
            },
            {
                title: 'Characters',
                tips: "And you will learn to write the character “you” following the correct stroke order:",
                wordsList: [{
                    pinyin: "nǐ",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-02-ni.svg")
                }, ]
            },
        ]
    },
    {
        // lessonId: 3,
        // nameHanzi: "一二三四五 (上)",
        // namePinyin: "Yī èr sān sì wǔ (shàng)",
        // nameEnglish: "One, two, three, four, five",
        // classPinyin: "Dì sān kè",
        // classHanzi: "第三课",
        syllabus: [{
                title: 'Vocabulary',
                tips: "In this lesson, you’ll learn new words such as “teacher”,“one”, “two”, “three”, “four” and “five”:",
                wordsList: [{
                        pinyin: "lǎoshī",
                        hanzi: "老师"
                    },
                    {
                        pinyin: "yī ",
                        hanzi: "一"
                    },
                    {
                        pinyin: "èr",
                        hanzi: "二"
                    },
                    {
                        pinyin: "sān",
                        hanzi: "三"
                    },
                    {
                        pinyin: "sì",
                        hanzi: "四"
                    },
                    {
                        pinyin: "wǔ",
                        hanzi: "五"
                    },
                ]
            },
            {
                title: 'Phrases',
                tips: "You’ll also learn new phrases such as  “Hello, teacher!”:",
                wordsList: [{
                    pinyin: "lǎoshī hǎo!",
                    hanzi: "老师好!"
                }, ]
            },
            {
                title: 'Knowledge',
                tips: "You will know how count from 1 to 5 and how to greet your teacher:",
                wordsList: [{
                        pinyin: "yī èr sān sì wǔ",
                        hanzi: " 一 二 三 四 五"
                    },
                    {
                        pinyin: "lǎoshī hǎo!",
                        hanzi: "老师好!"
                    },
                ]
            },
        ]
    },
    {
        // lessonId: 4,
        // nameHanzi: "一二三四五 (下)",
        // namePinyin: "Yī èr sān sì wǔ (xià)",
        // nameEnglish: "One, two, three, four, five",
        // classPinyin: "Dì sì kè",
        // classHanzi: "第四课",
        syllabus: [{
                title: 'Phrases',
                tips: "In this lesson, you’ll review the words “teacher”, “one”, “two”, “three”, “four”, “five”,and the phase “Hello teacher”:",
                wordsList: [{
                        pinyin: "lǎoshī",
                        hanzi: "老师"
                    },
                    {
                        pinyin: "yī ",
                        hanzi: "一"
                    },
                    {
                        pinyin: "èr",
                        hanzi: "二"
                    },
                    {
                        pinyin: "sān",
                        hanzi: "三"
                    },
                    {
                        pinyin: "sì",
                        hanzi: "四"
                    },
                    {
                        pinyin: "wǔ",
                        hanzi: "五"
                    },
                    {
                        pinyin: "lǎoshīhǎo!",
                        hanzi: "老师好！"
                    },
                ]
            },
            {
                title: 'Phrases',
                tips: "You’ll also learn how to write the two basic strokes — horizontal and vertical:",
                wordsList: [{
                    pinyin: "héngzhé",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-04-hengzhe.svg")
                }, ]
            },
            {
                title: 'Characters',
                tips: "And you will learn to write the character “you” following the correct stroke order:",
                wordsList: [{
                    pinyin: "sān",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-04-san.svg")
                }, {
                    pinyin: "wǔ",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-04-wu.svg")
                }, ]
            },
        ]
    },
    {
        // lessonId: 5,
        // nameHanzi: "六七八九十 (上)",
        // namePinyin: "Liù qī bā jiǔ shí (shàng)",
        // nameEnglish: "Six, seven, eight, nine, ten",
        // classPinyin: "Dì wǔ kè",
        // classHanzi: "第五课",
        syllabus: [{
                title: 'Vocabulary',
                tips: "In this lesson, you’ll learn new words such as the numbers “six”, “seven”, “eight”, “nine” and “ten”,and the phrase “Goodbye”:",
                wordsList: [{
                        pinyin: "liù",
                        hanzi: "六"
                    },
                    {
                        pinyin: "qī",
                        hanzi: "七"
                    },
                    {
                        pinyin: "bā",
                        hanzi: "八"
                    },
                    {
                        pinyin: "jiǔ",
                        hanzi: "九"
                    },
                    {
                        pinyin: "shí",
                        hanzi: "十"
                    },
                    {
                        pinyin: "zài jiàn",
                        hanzi: "再见"
                    },
                ]
            },
            {
                title: 'Phrases',
                tips: "You’ll discover the difference between the phrases “Hello” and “Goodbye” and learn to use themappropriately:",
                wordsList: [{
                        pinyin: "Nǐhǎo!",
                        hanzi: "你好!"
                    },
                    {
                        pinyin: "Zàijiàn!",
                        hanzi: "再见！"
                    },
                ]
            },
            {
                title: 'Knowledge',
                tips: "You will know how count from 6 to 10 and how to say goodbye:",
                wordsList: [{
                        pinyin: "liù qī bā jiǔ shí",
                        hanzi: " 六七八九十"
                    },
                    {
                        pinyin: "Zàijiàn!",
                        hanzi: "再见!"
                    },
                ]
            },
        ]
    },
    {
        // lessonId: 6,
        // nameHanzi: "六七八九十 (下)",
        // namePinyin: "Liù qī bā jiǔ shí (xià)",
        // nameEnglish: "Six, seven, eight, nine, ten",
        // classPinyin: "Dì liù kè",
        // classHanzi: "第六课",
        syllabus: [{
                title: 'Vocabulary',
                tips: "In this lesson, you’ll review the numbers “six”, “seven”, “eight”, “nine” and “ten”,and the phrase “Goodbye”:",
                wordsList: [{
                        pinyin: "liù",
                        hanzi: "六"
                    },
                    {
                        pinyin: "qī",
                        hanzi: "七"
                    },
                    {
                        pinyin: "bā",
                        hanzi: "八"
                    },
                    {
                        pinyin: "jiǔ",
                        hanzi: "九"
                    },
                    {
                        pinyin: "shí",
                        hanzi: "十"
                    },
                    {
                        pinyin: "zài jiàn",
                        hanzi: "再见"
                    },
                ]
            },
            {
                title: 'Phrases',
                tips: "You’ll also learn how to write the left-falling and right-falling strokes:",
                wordsList: [{
                        pinyin: "piē",
                        strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-06-pie.svg")
                    },
                    {
                        pinyin: "nà",
                        strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-06-na.svg")

                    },
                ]
            },
            {
                title: 'Knowledge',
                tips: "And you will learn to write the characters “eight” and “ten” following the correct stroke order:",
                wordsList: [{
                    pinyin: "bā",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-06-ba.svg")
                }, {
                    pinyin: "shí",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-06-shi.svg")
                }, ]
            },
        ]
    },
    {
        // lessonId: 7,
        // nameHanzi: "拼音一 | a o e i u ü",
        // namePinyin: "Pīnyīn yī | a o e i u ü",
        // nameEnglish: "Pronunciation 1 | a o e i u ü",
        // classPinyin: "Dì qī kè",
        // classHanzi: "第七课",
        syllabus: {
            pronunciation: "a o e i u ü",
            knowledge: [{
                    content: "Welcome to your first Pinyin lesson —  a lesson on Chinese pronunciation."
                },
                {
                    content: "The name “pinyin” (拼音 pīnyīn) in Chinese refers to the system for transcribing the sound of Chinese characters using latin letters."
                },
                {
                    content: "This lesson will focus on the following sounds:"
                },
            ],
            skills: [{
                    content: "You will learn how to:"
                },
                {
                    content: "- shape your lips and mouth to pronounce these sounds;"
                },
                {
                    content: "- pronounce the sounds accurately with and without tone;"
                },
                {
                    content: "- read the sounds out loud;"
                },
                {
                    content: "- write the latin letters that represent each sound."
                },
            ]

        }
    },
    {
        // lessonId: 8,
        // nameHanzi: "总复习（一）",
        // namePinyin: "Zǒng fùxí (yī)",
        // nameEnglish: "Chapter 1 Review",
        // classPinyin: "Dì bā  kè",
        // classHanzi: "第八课",
        syllabus: {
            knowledge: {
                welcome: "Welcome to your first Review lesson!",

                content: "It’s time to review what you’ve already learned in Lessons 1-6 and practice the new vocabulary, grammar and characters in fun and meaningful ways."
            },
            skills: [{
                    title: "Listening",
                    content: 'Understand the new Chinese words, phrases and sentences in context;'
                },
                {
                    title: "Speaking",
                    content: 'Use the new Chinese words, phrases and sentences in games and situations to solve problems;'
                },
                {
                    title: "Reading",
                    content: 'Read the new words and pinyin sounds out loud;'
                },
                {
                    title: "Writing",
                    content: 'Review and practice writing the strokes and characters you’ve learned'
                },
            ]
        }
    },


    // chapter 2
    {
        // lessonId: 9,
        // nameHanzi: "我爱爸爸妈妈 (上)",
        // namePinyin: "Wǒ ài bàba māma (shàng)",
        // nameEnglish: "I Love Mom And Dad (I)",
        // classPinyin: "Dì yī kè",
        // classHanzi: "第九课",
        syllabus: [{
                title: 'Vocabulary',
                tips: "In this lesson, you’ll learn new words such as “you”, “good”, “I”, “am/is/are:”",
                wordsList: [{
                        pinyin: "nǐ",
                        hanzi: "你"
                    },
                    {
                        pinyin: "hǎo",
                        hanzi: "好"
                    },
                    {
                        pinyin: "wǒ",
                        hanzi: "我"
                    },
                    {
                        pinyin: "shì",
                        hanzi: "是"
                    },
                ]
            },
            {
                title: 'Phrases',
                tips: "You’ll also learn new phrases such as the greeting “Hello!” and introduction “I am”:",
                wordsList: [{
                        pinyin: "nǐ hǎo ",
                        hanzi: "你好!"
                    },
                    {
                        pinyin: "wǒ shì ......",
                        hanzi: "我是……"
                    },
                ]
            },
            {
                title: 'Knowledge',
                tips: "And you will know how to greet people and introduce yourself by saying:",
                wordsList: [{
                    pinyin: "Nǐ hǎo   Wǒ shì Dōng dōng .",
                    hanzi: " 你好！我是冬冬。"
                }, ]
            },
        ]
    },
    {
        // lessonId: 10,
        // nameHanzi: "我爱爸爸妈妈 (下)",
        // namePinyin: "Wǒ ài bàba māma (xià)",
        // nameEnglish: "I Love Mom And Dad (II)",
        // classPinyin: "Dì èr kè",
        // classHanzi: "第十课",
        syllabus: [{
                title: 'Vocabulary',
                tips: "In this lesson, you’ll review the words “you”, “good”, “I”, “am/is/are” and the introduction “I am”:",
                wordsList: [{
                        pinyin: "nǐ",
                        hanzi: "你"
                    },
                    {
                        pinyin: "hǎo",
                        hanzi: "好"
                    },
                    {
                        pinyin: "wǒ",
                        hanzi: "我"
                    },
                    {
                        pinyin: "shì",
                        hanzi: "是"
                    },
                    {
                        pinyin: "wǒ shì ......",
                        hanzi: "我是……"
                    }
                ]
            },
            {
                title: 'Strokes',
                tips: "You’ll also learn how to write the two basic strokes — horizontal and vertical:",
                wordsList: [{
                        pinyin: "héng",
                        strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-02-heng.svg")
                    },
                    {
                        pinyin: "shù",
                        strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-02-shu.svg")

                    },
                ]
            },
            {
                title: 'Characters',
                tips: "And you will learn to write the character “you” following the correct stroke order:",
                wordsList: [{
                    pinyin: "nǐ",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-02-ni.svg")
                }, ]
            },
        ]
    },
    {
        // lessonId: 11,
        // nameHanzi: "我有一个妹妹 (上)",
        // namePinyin: "Wǒ yǒu yī ge mèimei (shàng)",
        // nameEnglish: "I Have A Sister (I)",
        // classPinyin: "Dì sān kè",
        // classHanzi: "第十一课",
        syllabus: [{
                title: 'Vocabulary',
                tips: "In this lesson, you’ll learn new words such as “teacher”,“one”, “two”, “three”, “four” and “five”:",
                wordsList: [{
                        pinyin: "lǎoshī",
                        hanzi: "老师"
                    },
                    {
                        pinyin: "yī ",
                        hanzi: "一"
                    },
                    {
                        pinyin: "èr",
                        hanzi: "二"
                    },
                    {
                        pinyin: "sān",
                        hanzi: "三"
                    },
                    {
                        pinyin: "sì",
                        hanzi: "四"
                    },
                    {
                        pinyin: "wǔ",
                        hanzi: "五"
                    },
                ]
            },
            {
                title: 'Phrases',
                tips: "You’ll also learn new phrases such as  “Hello, teacher!”:",
                wordsList: [{
                    pinyin: "lǎoshī hǎo!",
                    hanzi: "老师好!"
                }, ]
            },
            {
                title: 'Knowledge',
                tips: "You will know how count from 1 to 5 and how to greet your teacher:",
                wordsList: [{
                        pinyin: "yī èr sān sì wǔ",
                        hanzi: " 一 二 三 四 五"
                    },
                    {
                        pinyin: "lǎoshī hǎo!",
                        hanzi: "老师好!"
                    },
                ]
            },
        ]
    },
    {
        // lessonId: 12,
        // nameHanzi: "我有一个妹妹 (下)",
        // namePinyin: "Wǒ yǒu yī ge mèimei (xià)",
        // nameEnglish: "I Have a Sister (II)",
        // classPinyin: "Dì sì kè",
        // classHanzi: "第十二课",
        syllabus: [{
                title: 'Phrases',
                tips: "In this lesson, you’ll review the words “teacher”, “one”, “two”, “three”, “four”, “five”,and the phase “Hello teacher”:",
                wordsList: [{
                        pinyin: "lǎoshī",
                        hanzi: "老师"
                    },
                    {
                        pinyin: "yī ",
                        hanzi: "一"
                    },
                    {
                        pinyin: "èr",
                        hanzi: "二"
                    },
                    {
                        pinyin: "sān",
                        hanzi: "三"
                    },
                    {
                        pinyin: "sì",
                        hanzi: "四"
                    },
                    {
                        pinyin: "wǔ",
                        hanzi: "五"
                    },
                    {
                        pinyin: "lǎoshīhǎo!",
                        hanzi: "老师好！"
                    },
                ]
            },
            {
                title: 'Phrases',
                tips: "You’ll also learn how to write the two basic strokes — horizontal and vertical:",
                wordsList: [{
                    pinyin: "héngzhé",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-04-hengzhe.svg")
                }, ]
            },
            {
                title: 'Phrases',
                tips: "And you will learn to write the character “you” following the correct stroke order:",
                wordsList: [{
                    pinyin: "sān",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-04-san.svg")
                }, {
                    pinyin: "wǔ",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-04-wu.svg")
                }, ]
            },
        ]
    },
    {
        // lessonId: 13,
        // nameHanzi: "我家有四口人 (上)",
        // namePinyin: "Wǒ jiā yǒu sì kǒu rén (shàng)",
        // nameEnglish: "There Are 4 People In My Family (I)",
        // classPinyin: "Dì wǔ kè",
        // classHanzi: "第十三课",
        syllabus: [{
                title: 'Vocabulary',
                tips: "In this lesson, you’ll learn new words such as the numbers “six”, “seven”, “eight”, “nine” and “ten”,and the phrase “Goodbye”:",
                wordsList: [{
                        pinyin: "liù",
                        hanzi: "六"
                    },
                    {
                        pinyin: "qī",
                        hanzi: "七"
                    },
                    {
                        pinyin: "bā",
                        hanzi: "八"
                    },
                    {
                        pinyin: "jiǔ",
                        hanzi: "九"
                    },
                    {
                        pinyin: "shí",
                        hanzi: "十"
                    },
                    {
                        pinyin: "zài jiàn",
                        hanzi: "再见"
                    },
                ]
            },
            {
                title: 'Phrases',
                tips: "You’ll discover the difference between the phrases “Hello” and “Goodbye” and learn to use themappropriately:",
                wordsList: [{
                        pinyin: "Nǐhǎo!",
                        hanzi: "你好!"
                    },
                    {
                        pinyin: "Zàijiàn!",
                        hanzi: "再见！"
                    },
                ]
            },
            {
                title: 'Knowledge',
                tips: "You will know how count from 6 to 10 and how to say goodbye:",
                wordsList: [{
                        pinyin: "liù qī bā jiǔ shí",
                        hanzi: " 六七八九十"
                    },
                    {
                        pinyin: "Zàijiàn!",
                        hanzi: "再见!"
                    },
                ]
            },
        ]
    },
    {
        // lessonId: 14,
        // nameHanzi: "我家有四口人 (下)",
        // namePinyin: "Wǒ jiā yǒu sì kǒu rén (xià)",
        // nameEnglish: "There Are 4 People In My Family (II)",
        // classPinyin: "Dì liù kè",
        // classHanzi: "第十四课",
        syllabus: [{
                title: 'Vocabulary',
                tips: "In this lesson, you’ll review the numbers “six”, “seven”, “eight”, “nine” and “ten”,and the phrase “Goodbye”:",
                wordsList: [{
                        pinyin: "liù",
                        hanzi: "六"
                    },
                    {
                        pinyin: "qī",
                        hanzi: "七"
                    },
                    {
                        pinyin: "bā",
                        hanzi: "八"
                    },
                    {
                        pinyin: "jiǔ",
                        hanzi: "九"
                    },
                    {
                        pinyin: "shí",
                        hanzi: "十"
                    },
                    {
                        pinyin: "zài jiàn",
                        hanzi: "再见"
                    },
                ]
            },
            {
                title: 'Phrases',
                tips: "You’ll also learn how to write the left-falling and right-falling strokes:",
                wordsList: [{
                        pinyin: "piē",
                        strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-06-pie.svg")
                    },
                    {
                        pinyin: "nà",
                        strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-06-na.svg")

                    },
                ]
            },
            {
                title: 'Knowledge',
                tips: "And you will learn to write the characters “eight” and “ten” following the correct stroke order:",
                wordsList: [{
                    pinyin: "bā",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-06-ba.svg")
                }, {
                    pinyin: "shí",
                    strokeImg: require("@/assets/img/19-Dashboard/syllabus/lesson-06-shi.svg")
                }, ]
            },
        ]
    },
    {
        // lessonId: 15,
        // nameHanzi: "拼音 (二) b p m f",
        // namePinyin: "Pīnyīn (èr) b p m f",
        // nameEnglish: "Pinyin 2 | B P M F",
        // classPinyin: "Dì qī kè",
        // classHanzi: "第十五课",
        syllabus: {
            pronunciation: "b p m f",
            knowledge: [{
                    content: "Welcome to your first Pinyin lesson —  a lesson on Chinese pronunciation."
                },
                {
                    content: "The name “pinyin” (拼音 pīnyīn) in Chinese refers to the system for transcribing the sound of Chinese characters using latin letters."
                },
                {
                    content: "This lesson will focus on the following sounds:"
                },
            ],
            skills: [{
                    content: "You will learn how to:"
                },
                {
                    content: "- shape your lips and mouth to pronounce these sounds;"
                },
                {
                    content: "- pronounce the sounds accurately with and without tone;"
                },
                {
                    content: "- read the sounds out loud;"
                },
                {
                    content: "- write the latin letters that represent each sound."
                },
            ]

        }
    },
    {
        // lessonId: 16,
        // nameHanzi: "总复习（二）",
        // namePinyin: "Zǒng fùxí (èr)",
        // nameEnglish: "Chapter 2 Review",
        // classPinyin: "Dì bā  kè",
        // classHanzi: "第十六课",
        syllabus: {
            knowledge: {
                welcome: "Welcome to your first Review lesson!",

                content: "It’s time to review what you’ve already learned in Lessons 1-6 and practice the new vocabulary, grammar and characters in fun and meaningful ways."
            },
            skills: [{
                    title: "Listening",
                    content: 'Understand the new Chinese words, phrases and sentences in context;'
                },
                {
                    title: "Speaking",
                    content: 'Use the new Chinese words, phrases and sentences in games and situations to solve problems;'
                },
                {
                    title: "Reading",
                    content: 'Read the new words and pinyin sounds out loud;'
                },
                {
                    title: "Writing",
                    content: 'Review and practice writing the strokes and characters you’ve learned'
                },
            ]
        }
    }



]