<template>
  <div class="syllabus-container">
    <div
      class="syllabus-box"
      v-if="currentLessonIndex % 8 !== 7 && currentLessonIndex % 8 !== 0"
    >
      <div
        class="syllabus-item"
        v-for="(item, index) in syllabusList"
        :key="index"
        :class="{
          noDisplay: index !== 0 && [1, 3, 5].indexOf(currentLessonIndex) > -1,
        }"
      >
        <div class="title-area">
          <span class="title">
            {{ item.title }}
          </span>
        </div>
        <div class="syllabus-content">
          <div class="tips">
            {{ item.tips }}
          </div>
          <div class="words-area">
            <div
              class="word-item"
              v-for="(ele, indexSon) in item.wordsList"
              :key="indexSon + 'son'"
            >
              <div class="syllabus-pinyin pinyin font-pinyin-medium">
                {{ ele.pinyin }}
              </div>
              <div class="syllabus-hanzi font-hanzi-medium" v-if="ele.hanzi">
                {{ ele.hanzi }}
              </div>
              <div
                class="syllabus-hanzi font-hanzi-medium"
                v-if="ele.strokeImg"
              >
                <img :src="ele.strokeImg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="syllabus-box lesson-7" v-if="currentLessonIndex % 8 === 7">
      <div class="item-area">
        <div class="title-area">
          <span class="title"> Knowledge </span>
        </div>
        <div class="syllabus-content">
          <div class="words-area">
            <div
              class="word-item"
              v-for="(ele, indexSon) in syllabusInfo.syllabus.knowledge"
              :key="indexSon + 'son'"
            >
              <div class="">
                {{ ele.content }}
              </div>
            </div>
          </div>
          <div class="pronunciation font-hanzi-medium">
            {{ syllabusInfo.syllabus.pronunciation }}
          </div>
        </div>
      </div>
      <div class="item-area">
        <div class="title-area">
          <span class="title"> Skills </span>
        </div>
        <div class="syllabus-content">
          <div class="words-area">
            <div
              class="word-item"
              v-for="(ele, indexSon) in syllabusInfo.syllabus.skills"
              :key="indexSon + 'son'"
            >
              <div class="">
                {{ ele.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="syllabus-box lesson-8" v-if="currentLessonIndex % 8 === 0">
      <div class="item-area">
        <div class="title-area">
          <span class="title"> Knowledge </span>
        </div>
        <div class="syllabus-content">
          <div class="welcome">
            {{ syllabusInfo.syllabus.knowledge.welcome }}
          </div>
          <div class="welcome">
            {{ syllabusInfo.syllabus.knowledge.content }}
          </div>
        </div>
      </div>

      <div class="intrudction">In this lesson, you will learn to:</div>

      <div
        class="item-area"
        v-for="(ele, indexSon) in syllabusInfo.syllabus.skills"
        :key="indexSon + 'son'"
      >
        <div class="title-area">
          <span class="title"> {{ ele.title }} </span>
        </div>
        <div class="syllabus-content">
          <div class="words-area">
            <div class="word-item">
              <div class="">
                {{ ele.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SyllabusReview",
  created() {
    console.log(this.syllabusInfo, this.syllabusInfo.lessonId);
    this.currentLessonIndex = this.syllabusInfo.lessonId;
    // if ([1, 2, 3, 5].indexOf(this.currentLessonIndex) > -1) {
    this.syllabusList = this.syllabusInfo.syllabus;
    // }
  },
  props: {
    syllabusInfo: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      currentLessonIndex: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>